.p-menubar-end {
	.p-button {
		&, &:hover, &:focus, &:enabled:hover,  &:enabled:focus {
            background-color: transparent;
            border: none;
            box-shadow: none;
		}
	}

	.layout-terminal-info {
		color: #222222;
    }

    .manage-terminal-button {
		.p-button-label {
			width: 5px;
			flex: 0 0 auto;
		}
	}
}

.p-menubar.ods-headerbar {
    border-radius: 0px;
    border-top: none;
    border-left: none;
    border-right: none;
}
