.App {
  text-align: center;
  position: relative;
  overflow: hidden;
  background-color: #ffffff;
  height: 100vh;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  color: #181515;
}
